<template >
  <div class="home">
    <nav class="navbar navbar-expand-lg z100 is-sticky">
      <div class="container">
        <a class="navbar-brand" href="/"><img src="../assets/logo.svg" height="18"> </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent"  style="justify-content: flex-end;">
          <div class="form-inline my-2 my-lg-0 flex-column justify-content-end align-items-end">
            <img src="../assets/paysafe.svg" height="13"  />
            <span class="trusted-partner d-inline">Trusted partner since 2012</span>
          </div>
        </div>
      </div>
    </nav>
    <section class="z10">

      <div class="back-header">
        <canvas id="canvas-complex"></canvas>
      </div>
      <div class="header">
        <!--
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon fill="white" points="0,100 100,0 100,100"/>
        </svg>-->
        <div class="Section__layoutContainer" style="padding-bottom:20px;">
          <div class="Section__layout" style="padding-bottom: 0px;">
            <div class="ColumnLayout two">
              <div style="margin-top:30px;">
                <h1>
                  The best Skrill Neteller cashback offer and a welcome bonus for your friends!
                </h1>
                <p>
                  <a href="#register" class="button">Join now <i class="fal fa-chevron-right"></i></a>
                </p>

              </div>
              <div class="text-center">
                <img src="../assets/phone.png" class="top-phone" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section >
      <div class="Section__layoutContainer">
        <div class="Section__layout smaller-top-padding" style="padding-top: 40px">
          <div class="Section__title text-center">
            <h1 class="align-center">
              Want to be one step ahead? At Paynura, we give you the opportunity to earn lucrative cashback on your Skrill and Neteller activity.
            </h1>

          </div>
        </div>
      </div>
    </section>
    <section class="grey">
      <div class="Section__layoutContainer">
        <div class="Section__layout " >

          <div class="ColumnLayout two">
            <div>

              <img src="../assets/addon_2.png" class="addon_1" />
            </div>
            <div>
              <div class="Section__title text-lg-left">
                <h1>Who can participate?</h1>
                <p>New and existing Skrill and Neteller customers with active and verified accounts</p>
              </div>

            </div>
          </div>
          <div class="ColumnLayout two" style="padding-top:140px;">
            <div>
              <div class="Section__title text-lg-right">
                <h1>What can you get?</h1>
                <p>Attractive cashback based on your monthly Skrill and Neteller activity and 20 EUR, additional bonus for each eligible referred friend.</p>
              </div>
            </div>
            <div>
              <img src="../assets/addon_1.png" class="addon_1" />

            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="Section__layoutContainer">


        <div class="Section__layout" id="register">
          <div class="d-flex justify-content-center">

          </div>
          <div class="ColumnLayout two">

            <div >
              <ContactUs/>
            </div>
            <div class="text-left add-rule-desktop">
              <div class="Section__title">
                <h1 class="text-lg-left add-padding-desktop-h1">How can you join<br> the Paynura cashback<br> bonus program?</h1>
              </div>

              <ul class="default-list">
                <li>All accounts need to be verified</li>
                <li>We will get back to you within 48 hours with confirmation and details on your eligibility and successful enrollment.</li>
              </ul>

            </div>


          </div>
        </div>
      </div>
    </section>
    <section class="grey makebackground">
      <div class="Section__layoutContainer">
        <div class="Section__layout">
          <div class="Section__title text-center">
            <h1 class="align-center">
              About Paynura Cashback Bonus Program
            </h1>

          </div>
          <div>
            <ul class="default-list text-left">
              <li>Earn a free cashback bonus by using your Skrill or Neteller ewallet for deposits to gaming merchants.</li>
              <li>Your eligible cashback bonus is transferred to your account on the 15th of the month for the previous month.</li>
              <li>If you don't have Skrill or Neteller account yet, register first and submit the application afterward.</li>
              <li>You can also invite a friend and earn a 20 EUR bonus.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="white">
      <div class="Section__layoutContainer">
        <div class="Section__layout">
          <div class="Section__title text-center">
            <h1 class="align-center">
              Exclusive Support
            </h1>
            <p>We are here to answer all your questions, just drop us a line<br/>

            </p>
          </div>
          <div class="ColumnLayout four">
            <div class="support_wrap">

            </div>
            <div class="support_wrap">
              <div>
                <img src="../assets/icon_telegram.png" />
              </div>
              <div>
                <span>Telegram</span>
                <label>@markpaynura</label>
              </div>
            </div>
            <div>
              <div class="support_wrap">
                <div>
                  <img src="../assets/icon_email.png" />
                </div>
                <div>
                  <span>Email</span>
                  <label>manager@paynura.com</label>
                </div>
              </div>
            </div>
            <div>
              <div class="support_wrap">

              </div>
            </div>

          </div>

        </div>
      </div>
    </section>


    <section  class="white" style="display:none;">
      <div class="Section__layoutContainer">
        <div class="Section__layout ">
          <div class="Section__title">
            <h2 class="align-center">
              Paynura
            </h2>
            <p class="list-color mt-4" style="max-width: 900px; margin:0px auto;">A Paysafe partner for Skrill and Neteller affiliate programs since 2012. Supporting more than 300 gaming affiliates worldwide. Dedicated support provider to affiliates and players.</p>
          </div>

        </div>

      </div>
    </section>
    <footer class="grey" style="">
      <div class="Section__layoutContainer">
        <div class="Section__layout " style="padding-top:10px; padding-bottom:20px;">
          <div class="d-flex justify-content-between align-items-center bottom-foot">
            <div>
              <a href="https://paynura.com/en/terms" class="link">Terms & Conditions</a>
              <a href="https://paynura.com/en/privacy-policy" class="link">Privacy Policy</a>
            </div>
            <div>
              <a href="https://www.facebook.com/paynura"><i class="fab fa-facebook-f"></i></a>
              <a href="https://www.instagram.com/paynura_/"><i class="fab fa-instagram"></i></a>
              <a href="https://www.youtube.com/channel/UC5Un_Px3Z1e7HBNgwz74yiA"><i class="fab fa-youtube"></i></a>
              <a href="https://www.linkedin.com/company/paynura"><i class="fab fa-linkedin"></i></a>
              <a href="https://twitter.com/PaynuraCOM"><i class="fab fa-twitter"></i></a>
            </div>
          </div>
        </div>
      </div>

    </footer>
  </div>

</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
