<template>
  <b-container class="background">
    <b-row>
      <b-col lg="12" align="center">
        <div class="anchored-to-top-30">
          <div class="">
            <h1 class="mb-5" style="font-weight: 300; display: none;"><span class="color-primary">Contact Us</span></h1>

          </div>
        </div>
      </b-col>
      <b-col lg="12" align="center" >
        <b-form class="short paynura"  v-if=" !submitted">
          <div class="circles">
            <img src="../assets/circles.png" height="12">
          </div>
          <div class="text-center">
            <p class="default-paragraph">Choose you ewallet</p>
          </div>
          <div class="choose-brand">
            <div class="neteller" :class="{'selected': mode === 'neteller'}" @click="mode = 'neteller'">
              <img src="../assets/neteller_big.png">
            </div>
            <div class="skrill " :class="{'selected': mode === 'skrill'}" @click="mode = 'skrill'">
              <img src="../assets/skrill_big.png">
            </div>

          </div>
          <table class="form-table">
            <tr v-if="missingfields">
              <td colspan="2" class="error-message">Please fill in all required fields</td>
            </tr>
            <tr>
              <td width="38%"><label>Name</label></td>
              <td>
                <b-form-input v-model="name" required :class="{ 'error-border': missingfields }"></b-form-input>
              </td>
            </tr>
            <tr>
              <td width="38%"><label>Email</label></td>
              <td>
                <b-form-input v-model="email" required :class="{ 'error-border': missingfields }"></b-form-input>
              </td>
            </tr>
            <tr>
              <td width="38%"><label>Account ID</label></td>
              <td>
                <b-form-input v-model="accountid" required :class="{ 'error-border': missingfields }"></b-form-input>
              </td>
            </tr>
            <tr>
              <td width="38%"><label>or Register</label></td>
              <td>
                <a v-if="mode === 'neteller'"
                   href="http://wlneteller.adsrv.eacdn.com/C.ashx?btag=a_104307b_3766c_&affid=529&siteid=104307&adid=3766&c="
                   class="button outline-2"
                   target="_blank"
                >
                  New Account
                </a>
                <a v-if="mode === 'skrill'"
                    href="http://wlskrill.adsrv.eacdn.com/C.ashx?btag=a_104306b_3264c_&affid=42750&siteid=104306&adid=3264&c="
                   class="button outline-2"
                   target="_blank"
                  >
                  New Account
                </a>
              </td>
            </tr>
            <tr style="display: none">
              <td width="38%"><label>Refer a friend</label></td>
              <td>
                <a v-if="mode === 'neteller'"
                   href="http://wlneteller.adsrv.eacdn.com/C.ashx?btag=a_103780b_3766c_&affid=529&siteid=103780&adid=3766&c="
                   class="button outline-2"
                   target="_blank"
                   @click="onSubmit">
                  New Account
                </a>
                <a v-if="mode === 'skrill'"
                    href="http://wlskrill.adsrv.eacdn.com/C.ashx?btag=a_103779b_3264c_&affid=42750&siteid=103779&adid=3264&c="
                   target="_blank"
                   class="button outline-2"
                   @click="onSubmit">
                  New Account
                </a>
              </td>
            </tr>
            <tr style="display: none">
              <td width="38%"><label>Friend's Account ID</label></td>
              <td>
                <b-form-input v-model="accountid_friend" required></b-form-input>
              </td>
            </tr>
          </table>
          <button :disabled="sending" class="button main mt-4" @click.prevent="onSubmit"><span>Submit Application</span> <i class="fal fa-chevron-right"></i></button>
        </b-form>
        <h4 class="anchored-to-top-30" v-if="submitted">Your request has been submitted.</h4>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import axios from 'axios'

export default {
  name: "ContactUs",
  data() {
    return {
      submitted: false,
      email: "",
      name: "",
      accountid: "",
      accountid_friend: "",
      mode: "neteller",
      missingfields : false,
      sending : false
    }
  },
  methods: {
    onSubmit() {
      const params = new URLSearchParams();
      if(this.email.length == 0 || this.name.length == 0 || this.accountid.length == 0) {
        this.missingfields = true
      } else {
        this.sending = true
        this.missingfields = false
        params.append('email', this.email);
        params.append('name', this.name);
        params.append('accountid_friend', this.accountid_friend);
        params.append('accountid', this.accountid);
        params.append('mode', this.mode);
        var _this = this
        axios.post('/contact_send.php', params).then((e) => {
            _this.sending = false
          _this.submitted = true
          _this.email = ""
          _this.name = ""
          _this.accountid_friend = ""
          _this.accountid = ""
        }, (e) => {
            _this.sending = false
          console.log(e)
          alert("There was an error processing your request.")
        });
      }
    }
  }
}

</script>
