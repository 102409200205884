import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import ContactUs from './components/ContactUs.vue'

Vue.config.productionTip = false
Vue.component("ContactUs", ContactUs)

new Vue({
  render: h => h(App),
}).$mount('#app')
